import { t } from '@/utility/localization';

export const PLATFORM_TYPE = {
  DISCORD: 'discord',
  TELEGRAM: 'telegram',
  WHATSAPP: 'whatsapp',
  FACEBOOK: 'facebook',
  SLACK: 'slack',
  LINKEDIN: 'linkedin',
  LINE_GROUP: 'line'
};

export const PLATFORM_THEME_HEX = {
  [PLATFORM_TYPE.DISCORD]: '#5865F2',
  [PLATFORM_TYPE.TELEGRAM]: '#2AABEE',
  [PLATFORM_TYPE.WHATSAPP]: '#25D366',
  [PLATFORM_TYPE.FACEBOOK]: '#0165E1',
  [PLATFORM_TYPE.SLACK]: '#7C3085',
  [PLATFORM_TYPE.LINKEDIN]: '#2867B2',
  [PLATFORM_TYPE.LINE_GROUP]: '#06C755'
};

export const getChatPlatforms = () => [
  {
    name: t('discord'),
    iconName: 'discord-coloured',
    btnIconName: 'discord-neutral',
    description: t('link-your-discord-account-to-connect-your-server'),
    label: t('connect-discord'),
    type: PLATFORM_TYPE.DISCORD
  },
  {
    name: t('telegram'),
    iconName: 'telegram-coloured',
    btnIconName: 'telegram-coloured',
    description: t('link-your-telegram-account-to-connect-groups'),
    label: t('connect-telegram'),
    type: PLATFORM_TYPE.TELEGRAM
  },
  {
    name: t('whatapp'),
    iconName: 'whatsapp-coloured',
    btnIconName: 'whatsapp-coloured',
    label: t('connect-whatsapp'),
    description: t('link-your-whatsapp-account-to-connect-channels'),
    type: PLATFORM_TYPE.WHATSAPP
  },
  {
    name: t('facebook-group'),
    iconName: 'facebook-coloured',
    btnIconName: 'facebook-neutral',
    label: t('add-facebook-group'),
    description: t('add-a-facebook-group-link'),
    type: PLATFORM_TYPE.FACEBOOK
  },
  {
    name: t('slack'),
    iconName: 'slack-coloured',
    btnIconName: 'slack-neutral',
    label: t('add-slack-channel'),
    description: t('add-your-slack-channel-link'),
    type: PLATFORM_TYPE.SLACK
  },
  {
    name: t('linkedin2'),
    iconName: 'linkedin-coloured',
    btnIconName: 'linkedin-neutral',
    label: t('connect-linkedin-group'),
    description: t('add-your-linkedin-group-link'),
    type: PLATFORM_TYPE.LINKEDIN
  },
  {
    name: t('line'),
    iconName: 'line-coloured',
    btnIconName: 'line-neutral',
    label: t('add-line-group-chat'),
    description: t('add-your-line-group-chat-link'),
    type: PLATFORM_TYPE.LINE_GROUP
  }
];
