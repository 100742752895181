import React from 'react';

const className = 'c-IconsV2-Telegram';

const Telegram = ({ customClassNames = '', width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <circle cx="24" cy="24" r="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 24C44 35.045 35.045 44 24 44C12.955 44 4 35.045 4 24C4 12.955 12.955 4 24 4C35.045 4 44 12.955 44 24ZM24.7167 18.765C22.7717 19.5733 18.8833 21.2483 13.0533 23.7883C12.1067 24.165 11.61 24.5333 11.565 24.8933C11.4883 25.5033 12.2517 25.7433 13.2883 26.0683C13.43 26.1133 13.5767 26.1583 13.7267 26.2083C14.7483 26.54 16.1217 26.9283 16.835 26.9433C17.4833 26.9567 18.2067 26.69 19.005 26.1433C24.4517 22.465 27.2633 20.6067 27.44 20.5667C27.565 20.5383 27.7383 20.5017 27.855 20.6067C27.9717 20.71 27.96 20.9067 27.9483 20.96C27.8717 21.2817 24.8817 24.0633 23.3317 25.5033C22.8483 25.9517 22.5067 26.27 22.4367 26.3433C22.28 26.505 22.12 26.66 21.9667 26.8083C21.0167 27.7217 20.3067 28.4083 22.0067 29.5283C22.8233 30.0667 23.4767 30.5117 24.1283 30.955C24.84 31.44 25.55 31.9233 26.47 32.5267C26.7033 32.68 26.9267 32.8383 27.145 32.9933C27.9733 33.585 28.7183 34.115 29.6383 34.0317C30.1717 33.9817 30.725 33.48 31.005 31.9817C31.6667 28.4383 32.97 20.765 33.2717 17.6017C33.29 17.339 33.2788 17.0751 33.2383 16.815C33.2141 16.6049 33.1118 16.4116 32.9517 16.2733C32.7133 16.0783 32.3433 16.0367 32.1767 16.04C31.425 16.0533 30.2717 16.455 24.7167 18.765V18.765Z"
        fill="#2AABEE"
      />
    </svg>
  );
};

export default Telegram;
