import Discord from '@/components/common/IconsV2/Discord';
import Facebook from '@/components/common/IconsV2/Facebook';
import LinkedInIcon from '@/components/common/IconsV2/LinkedInIcon';
import Slack from '@/components/common/IconsV2/Slack';
import Telegram from '@/components/common/IconsV2/Telegram';
import Whatsapp from '@/components/common/IconsV2/Whatsapp';
import { defaultBannerImg, SEEN_SIGN_UP_MODAL } from './constants';
import config from '@/utility/config';
import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { getImageUrlWithSizing } from '@/utility/getImageUrlWithSizing';
import { formatNewPricingApiToOldFormat } from '@/utility/communityHelpers';
import sessionStorageService from '@/utility/sessionStorageService';
import { t } from '@/utility/localization';

import {
  EVENTS_SECTION,
  COURSES_SECTION,
  BENEFITS_SECTION
} from './constants';
import { PLATFORM_TYPE } from '@/pages/portal/chats/Components/ChatComponent/constants';
import Icon from '@/components/npl/Icon';

export const getPortalUrl = (middlePath, communityId) => {
  return `/portal/${middlePath}?activeCommunityId=${communityId}`;
};

export const getPlatformDetails = (platform) => {
  switch (platform) {
    case PLATFORM_TYPE.DISCORD:
      return {
        displayName: 'Discord',
        getIcon: (props) => <Discord {...props} />,
        overviewText: 'Discord',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-discord-server', {
            communityName
          })
      };
    case PLATFORM_TYPE.WHATSAPP:
      return {
        displayName: 'WhatsApp',
        getIcon: (props) => <Whatsapp {...props} />,
        overviewText: 'WhatsApp',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-whatsapp-group', {
            communityName
          })
      };
    case PLATFORM_TYPE.TELEGRAM:
      return {
        displayName: 'Telegram',
        getIcon: (props) => <Telegram {...props} />,
        overviewText: 'Telegram',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-telegram-group', {
            communityName
          })
      };
    case PLATFORM_TYPE.FACEBOOK:
      return {
        displayName: 'Facebook',
        getIcon: (props) => <Facebook {...props} />,
        overviewText: 'Facebook',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-facebook-group', {
            communityName
          })
      };
    case PLATFORM_TYPE.SLACK:
      return {
        displayName: 'Slack',
        getIcon: (props) => <Slack {...props} />,
        overviewText: 'Slack',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-slack-workspace', {
            communityName
          })
      };
    case PLATFORM_TYPE.LINKEDIN:
      return {
        displayName: 'LinkedIn',
        getIcon: (props) => <LinkedInIcon {...props} />,
        overviewText: 'LinkedIn',
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-linkedin-group', {
            communityName
          })
      };
    case PLATFORM_TYPE.LINE_GROUP:
      return {
        displayName: t('line'),
        getIcon: (props) => (
          <Icon name="line-coloured" path="social" {...props} />
        ),
        overviewText: t('line'),
        getJoinText: (communityName) =>
          t('join-communityname-to-get-access-to-the-line-group-chat', {
            communityName
          })
      };
    default:
      return null;
  }
};

// Map where the sections array comes from to check in a loop if there is data to show
export const sectionToResponseArrayMapping = {
  [EVENTS_SECTION]: ['upcomingEvents', 'pastEvents'],
  [COURSES_SECTION]: ['communityFolders'],
  [BENEFITS_SECTION]: 'benefits'
};

export const getPlatformImgProps = (url) => {
  return {
    alt: 'platform image',
    desktopImgProps: {
      src: url,
      width: 48,
      height: 48
    },
    mobileImgProps: {
      src: url,
      width: 48,
      height: 48
    }
  };
};

export const getUserImgProps = (url) => {
  return {
    alt: 'user profile image',
    desktopImgProps: {
      src: url,
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: url,
      width: 40,
      height: 40
    }
  };
};

export const getCommunityProfileImgProps = (url, width, height, alt) => {
  return {
    alt: alt || 'community profile image',
    desktopImgProps: {
      src: url,
      layout: 'responsive',
      width: width,
      height: height,
      priority: true,
      quality: 25,
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: url,
      layout: 'responsive',
      width: width,
      height: height,
      priority: true,
      quality: 25,
      objectFit: 'cover'
    }
  };
};

export const getHostProfileImageData = (url) => {
  return {
    alt: 'Host profile image',
    desktopImgProps: {
      src: url,
      layout: 'fill'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill'
    }
  };
};

const defaultEventImage = `${STATIC_ASSET_BASE_URL}/nasIO/common/png/default-event-banner-image.png`;

export const getCommunityBannerImageLiveEvents = (url) => {
  let imageUrl = url || defaultEventImage;
  return {
    alt: 'community banner image',
    desktopImgProps: {
      src: imageUrl,
      layout: 'fill',
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: imageUrl,
      layout: 'fill',
      objectFit: 'cover'
    }
  };
};

export const getCourseBannerImgData = (url) => {
  return {
    alt: 'Course banner image',
    desktopImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover'
    }
  };
};

export const getCommunityOverviewImageProps = (url, width, height) => {
  return {
    alt: 'community overview Image',
    desktopImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover',
      width: width,
      height: height,
      priority: true,
      quality: 50
    },
    mobileImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center',
      width: width,
      height: height,
      priority: true,
      quality: 25
    }
  };
};

export const getLiveEventHostProfileImageProps = (url) => {
  return {
    alt: 'Live Event profile image',
    mobileImgProps: {
      src: url,
      width: 32,
      height: 32
    }
  };
};

export const getExclusiveContentImage = (url) => {
  return {
    alt: 'Exclusive Content Image',
    mobileImgProps: {
      src: url,
      layout: 'fill'
    },
    desktopImgProps: {
      src: url,
      layout: 'fill'
    }
  };
};

export const textAndImageCardImageImage = (url) => {
  return {
    alt: `TextAndImageCardImage card`,
    mobileImgProps: {
      src: url,
      width: 366,
      height: 244
    },
    desktopImgProps: {
      src: url,
      width: 360,
      height: 240
    }
  };
};

export const getTestimonialImage = (url) => {
  return {
    alt: 'Testimonial Image',
    mobileImgProps: {
      src: url,
      width: 48,
      height: 48,
      layout: 'responsive'
    },
    desktopImgProps: {
      src: url,
      width: 48,
      height: 48,
      layout: 'responsive'
    }
  };
};

export const getExclusiveContentColectionIconImage = (url) => {
  return {
    alt: 'Exclusive Content Icon Image',
    mobileImgProps: {
      src: url,
      width: 32,
      height: 40
    }
  };
};

export function getFirstLetters(string) {
  const firstLetters = string
    ?.split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export const getHighestIntervalPricing = (pricingData) => {
  if (!pricingData) return null;
  return pricingData?.reduce((acc, curr) => {
    if (curr?.recurring?.interval === acc?.recurring?.interval) {
      // If the intervals are the same, we want to return the one with the highest interval count
      return curr?.recurring?.interval_count >
        acc?.recurring?.interval_count
        ? curr
        : acc;
    }
    // If the intervals are different we want to return the one with the highest interval
    if (curr?.recurring?.interval !== acc?.recurring?.interval) {
      if (
        curr?.recurring?.interval === 'year' &&
        acc?.recurring?.interval === 'month'
      ) {
        return curr;
      }
      if (
        curr?.recurring?.interval === 'month' &&
        acc?.recurring?.interval === 'year'
      ) {
        return acc;
      }

      return curr?.recurring?.interval === 'year' ? curr : acc;
    }
  }, pricingData?.[0]);
};

export const getLowestIntervalPricing = (pricingData) => {
  if (!pricingData) return null;
  return pricingData?.reduce((acc, curr) => {
    if (curr?.recurring?.interval === acc?.recurring?.interval) {
      // If the intervals are the same, we want to return the one with the lowest interval count
      return curr?.recurring?.interval_count <
        acc?.recurring?.interval_count
        ? curr
        : acc;
    }
    // If the intervals are different we want to return the one with the lowest interval
    if (curr?.recurring?.interval !== acc?.recurring?.interval) {
      if (
        curr?.recurring?.interval === 'year' &&
        acc?.recurring?.interval === 'month'
      ) {
        return acc;
      }
      if (
        curr?.recurring?.interval === 'month' &&
        acc?.recurring?.interval === 'year'
      ) {
        return curr;
      }

      return curr?.recurring?.interval === 'year' ? acc : curr;
    }
  }, pricingData?.[0]);
};

export const getMetaDataProps = (community) => {
  return {
    title: `${community?.title} | Nas.io Communities`,
    description: `Join ${community?.title}, Hosted by ${community?.hostName}.`,
    imageUrl: community?.backgroundImage ?? defaultBannerImg,
    thumbnailUrl: getImageUrlWithSizing(
      community?.backgroundImage,
      175,
      175
    ),
    url: `${config.siteBaseUrl}${community?.slug}`,
    canonicalUrl: `${config.siteBaseUrl}${community?.slug}`
  };
};

export const formatPrice = (priceData) => {
  const formattedNewPricingDetails =
    formatNewPricingApiToOldFormat(priceData);

  formattedNewPricingDetails?.sort(function (a, b) {
    return a?.recurring?.interval_count - b?.recurring?.interval_count;
  });

  return formattedNewPricingDetails;
};

/**
 * For given community id, should the signup overlay be visible on community landing page
 * @param {String} communityId
 * @param {Object} communityConfig
 * @param {Boolean} communityConfig.hideSignUpOverlay
 * @returns {Boolean} true if popup should be initally visible
 */
export const getIsSignupPopupInitiallyVisible = ({
  communityId,
  communityConfig,
  isPendingApproval
}) => {
  if (isPendingApproval) {
    return false;
  }
  const hideSignUpOverlay = communityConfig?.hideSignUpOverlay;

  // communtiy ids as comma separated string.
  const signupModalSeenCommIdsStr =
    sessionStorageService.getItem(SEEN_SIGN_UP_MODAL) || '';
  // convert comma separated string of community id to array of community ids.
  const signupModalSeenCommIdsArr = signupModalSeenCommIdsStr
    ? signupModalSeenCommIdsStr.split(',')
    : [];

  const seenSignUpModal = signupModalSeenCommIdsArr.includes(communityId);
  const isSignupOverlayInitiallyVisible =
    !hideSignUpOverlay && !seenSignUpModal;

  return isSignupOverlayInitiallyVisible;
};

/**
 * Add given community id to list of community ids for which initial signup overlay is seen.
 * @param {String} communityId
 */
export const markInitialSignupOverlaySeen = (communityId) => {
  // communtiy ids as comma separated string.
  const signupModalSeenCommIdsStr =
    sessionStorageService.getItem(SEEN_SIGN_UP_MODAL) || '';
  // convert comma separated string of community id to array of community ids.
  let signupModalSeenCommIdsArr = signupModalSeenCommIdsStr
    ? signupModalSeenCommIdsStr.split(',')
    : [];

  // if community id already available. then do nothing.
  if (signupModalSeenCommIdsArr.includes(communityId)) return;

  // else add community id to session storage in csv format.
  signupModalSeenCommIdsArr.push(communityId);
  const updatedSignupModalSeenCommIdsStr =
    signupModalSeenCommIdsArr.join(',');

  sessionStorageService.setItem(
    SEEN_SIGN_UP_MODAL,
    updatedSignupModalSeenCommIdsStr
  );
};

export const formatCompactCount = (viewCount) => {
  if (!viewCount) return '0';

  if (viewCount >= 1000) {
    const formattedCount = (viewCount / 1000).toFixed(1);
    // Remove trailing zero if it exists
    return `${parseFloat(formattedCount)}k`;
  } else {
    return viewCount.toString();
  }
};
