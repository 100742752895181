import { t } from '@/utility/localization';

import { getLowestIntervalPricing } from '@/features/community/utils';

import { getPaymentIntervalOptions } from '@/contexts/SettingsSetupContext/constants';

import { isNonDecimalCurrency } from './checkoutUtils';
import config from './config';
import { formatNumber } from './helpers';
import { readFriendlyPhoneNumber } from './stringHelper';

export const getPhoneNumberFromWaPhoneId = (waPhoneId) => {
  try {
    if (waPhoneId.startsWith('+') && !waPhoneId.includes('@'))
      return waPhoneId;
    const phoneNumber = '+' + waPhoneId.split('@')[0];
    return readFriendlyPhoneNumber(phoneNumber);
  } catch (e) {
    return config.fallbackWhatsappBotNumber || waPhoneId;
  }
};

export const getWhatsAppConnectionMetadata = (community) => {
  try {
    const {
      bots,
      isWhatsappExperienceCommunity,
      isWhatsappBotAdmin,
      isWhatsappBotInGroup
    } = community;
    const botWasPreviouslyConnected = !!bots?.length;
    const isBotAdminOfTheGroup =
      isWhatsappBotAdmin && isWhatsappBotInGroup;

    // Show connect flow if bot was NOT previously connected and bot is NOT admin of the group
    const showWhatAppConnectFlow =
      isWhatsappExperienceCommunity &&
      !isBotAdminOfTheGroup &&
      !botWasPreviouslyConnected;

    // Show re-connect flow if bot was previously connected and bot is NOT admin of the group
    const showWhatAppReconnectFlow =
      isWhatsappExperienceCommunity &&
      !isBotAdminOfTheGroup &&
      botWasPreviouslyConnected;

    // Show WA connection card if bot is NOT admin of the group
    const showWaConnectionCard =
      isWhatsappExperienceCommunity && !isBotAdminOfTheGroup;

    return {
      showWaConnectionCard,
      showWhatAppConnectFlow,
      showWhatAppReconnectFlow
    };
  } catch (e) {
    return {
      showWaConnectionCard: false,
      showWhatAppConnectFlow: false,
      showWhatAppReconnectFlow: false
    };
  }
};

// temporary formatting for new pricing api
export const formatNewPricingApiToOldFormat = (newPricingDetails) => {
  const formattedNewPricing = newPricingDetails?.prices?.map((price) => ({
    currency: price.currency,
    product: newPricingDetails?.productId,
    checkoutAmount: price.checkoutAmount,
    feeDetails: price.feeDetails,
    recurring: {
      interval: price.interval,
      interval_count: price.intervalCount
    },
    type: 'recurring',
    unit_amount: price.unitAmount,
    localCurrency: price.localCurrency,
    localPrice: price.localPrice,
    id: price.id,
    isDefault: price.isDefault
  }));

  return formattedNewPricing;
};

export const getAvailablePaymentIntervals = (currPaymentIntervals) => {
  const res = getPaymentIntervalOptions().filter(
    (paymentIntervalOption) => {
      const isSelectedPaymentInterval = !!currPaymentIntervals.find(
        (selectedPaymentInterval) =>
          selectedPaymentInterval.value === paymentIntervalOption.value
      );

      return !isSelectedPaymentInterval; // acceptable if not selected in currPaymentIntervals
    }
  );

  return res;
};

export const formatPaymentIntervalsForApi = (
  communityPaymentIntervals
) => {
  if (!Array.isArray(communityPaymentIntervals)) return [];

  const formattedPaymentIntervals = communityPaymentIntervals?.map(
    (item) => {
      return {
        currency: item?.currency,
        amount: item?.amount,
        interval: item.interval,
        intervalCount: item.intervalCount
      };
    }
  );
  return formattedPaymentIntervals;
};

export const sanitizeBotInformation = (botList, botConnected) => {
  if (!Array.isArray(botList)) return null;

  const currentBotInformation = botList?.find(
    (item) =>
      item?.type?.toLowerCase() === botConnected?.botName?.toLowerCase()
  );

  if (!currentBotInformation) {
    return null;
  }

  //`botInformation.status` is not reliable, so we have to use `botConnected` status instead
  return {
    ...currentBotInformation,
    botConnected: botConnected.botConnected
  };
};

export const getDiscountPeriod = (intervalCount) => {
  if (intervalCount === 12) {
    return `${t('year-0')}`;
  } else if (intervalCount > 1) {
    return `${intervalCount} ${t('months')}`;
  } else return `${t('month-0')}`;
};

export const getCommunityPriceTagText = ({
  pricingData,
  currency,
  discountedCodeData,
  isFreeCommunity,
  pricePerMonth,
  showSubtitle,
  fixedSubscriptionDuration
}) => {
  // if this community has only a single payment cycle, we don't need to show pricing interval
  const isOnlySinglePaymentCycle = fixedSubscriptionDuration === 1;

  // order goes monthly > 6 monthly > yearly
  const selectedOption = getLowestIntervalPricing(pricingData);
  const intervalText =
    selectedOption?.recurring?.interval === 'year'
      ? t('year-0')
      : selectedOption?.recurring?.interval_count > 1
        ? t('selectedoption-recurring-interval_count-months', {
            interval: selectedOption?.recurring?.interval_count
          })
        : t('month-0');
  const { intervalCount, trialDays } = discountedCodeData || {};
  if (discountedCodeData) {
    // Free trial discount code applied
    if (trialDays > 0) {
      return (
        <>
          <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-secondary">
            {t('discountedcodedata-trialdays-days-free-trial', {
              discountDays: trialDays
            })}
          </span>
          {showSubtitle && !isOnlySinglePaymentCycle && (
            <div className="mt-8 text-label-sm text-npl-text-icon-on-light-surface-secondary">
              {`${
                selectedOption?.currency?.toUpperCase() || 'USD'
              } ${formatNumber(
                selectedOption?.unit_amount / 100 || pricePerMonth,
                false,
                true
              )}/${intervalText} after`}
            </div>
          )}
        </>
      );
      // handle temporary discount
    } else if (intervalCount > 0) {
      const pricingOption = pricingData?.find((item) => {
        let itemInterval =
          item?.recurring?.interval === 'year'
            ? 12
            : item?.recurring?.interval_count;
        return itemInterval === intervalCount;
      });

      const priceToDisplay = formatNumber(
        pricingOption?.unit_amount -
          (discountedCodeData?.value / 100) * pricingOption?.unit_amount,
        true,
        true
      );

      const discountPeriod =
        intervalCount === 12 ? t('year-lowercase') : t('month-0');

      return (
        <>
          <span className="text-heading-xs font-semibold">
            {pricingOption?.currency?.toUpperCase() || 'USD'}{' '}
            {priceToDisplay}{' '}
            <span className="text-label-sm font-normal lowercase">
              {t('for-first-period', { period: discountPeriod })}
            </span>
          </span>
          {showSubtitle && (
            <div className="mt-2 text-label-sm text-npl-text-icon-on-light-surface-tertiary">
              {`${pricingOption?.currency?.toUpperCase() || 'USD'} ${t(
                'discountedpricepermonth-period-after',
                {
                  discountedPricePerMonth: formatNumber(
                    pricingOption?.unit_amount,
                    true,
                    true
                  ),
                  period: getDiscountPeriod(intervalCount)
                }
              )}`}
            </div>
          )}
        </>
      );
      // permanent discount
    } else {
      const discountPercentage = discountedCodeData?.value / 100;
      const originalAmount = selectedOption?.unit_amount / 100;
      const discountedAmount =
        originalAmount - originalAmount * discountPercentage;

      const formattedOriginalAmount = formatNumber(
        selectedOption?.unit_amount,
        true,
        true
      );

      return (
        <>
          <span className="text-label-lg font-semibold">
            {`${currency?.toUpperCase() || 'USD'} 
          ${formatNumber(discountedAmount, false, true)}`}
          </span>
          <span className="ml-4 text-label-lg text-npl-text-icon-on-light-surface-tertiary line-through">
            {formattedOriginalAmount}
          </span>
          {!isOnlySinglePaymentCycle && (
            <span className="text-label-md">/{intervalText}</span>
          )}
        </>
      );
    }
  } else if (isFreeCommunity) {
    return t('free');
  } else if (selectedOption) {
    return (
      <span>
        <span className="text-label-lg font-semibold">
          {selectedOption?.currency?.toUpperCase() || 'USD'}{' '}
          {formatNumber(
            selectedOption?.unit_amount / 100 || pricePerMonth,
            false,
            true
          )}
        </span>
        {!isOnlySinglePaymentCycle && (
          <span className="text-label-md">/{intervalText}</span>
        )}
      </span>
    );
  } else {
    return '';
  }
};

export const getInputRegexBasedOnCurrency = (currency) => {
  if (isNonDecimalCurrency(currency)) {
    return /^\d*$/;
  } else {
    return /^\d*\.?\d{0,2}$/;
  }
};

export const getNonDeletedApplicationFormFields = (applicationData) => {
  return applicationData?.filter(
    (applicationConfigDataField) => !applicationConfigDataField.isDeleted
  );
};

export const checkIsCommunityCheckoutRequired = (communityInfo) => {
  //Checkout is required when paid community or application config data fields are present
  const {
    isPaidCommunity,
    applicationConfigDataFields,
    applicationConfig
  } = communityInfo || {};

  const hasNonDeletedApplicationConfigDataFields =
    applicationConfigDataFields?.some(
      (applicationConfigDataField) => !applicationConfigDataField.isDeleted
    );

  const { hasApplication } = applicationConfig || {};

  return Boolean(
    isPaidCommunity ||
      hasNonDeletedApplicationConfigDataFields ||
      hasApplication
  );
};
