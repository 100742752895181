import { t } from '@/utility/localization';

export const NFT_DEFAULT_PRICING = {
  address: '',
  collectionName: ''
};

export const getPaymentIntervalOptions = () => [
  {
    weight: 1,
    label: t('month2'),
    value: 'monthly',
    interval: 'month',
    intervalCount: 1
  },
  {
    weight: 2,
    label: t('3-months'),
    value: 'quarterly',
    interval: 'month',
    intervalCount: 3
  },
  {
    weight: 3,
    label: t('6-months'),
    value: 'semi-annual',
    interval: 'month',
    intervalCount: 6
  },
  {
    weight: 4,
    label: t('year1'),
    value: 'annual',
    interval: 'year',
    intervalCount: 1
  }
];
