const CURRENCY_WITH_NON_DECIMAL_POINTS = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF'
];

export const getStripeAmountForCurrency = (currency, amount) => {
  if (CURRENCY_WITH_NON_DECIMAL_POINTS.includes(currency?.toUpperCase())) {
    return amount / 100;
  }
  return amount;
};

export const isNonDecimalCurrency = (currency) => {
  if (!currency) {
    return false;
  }
  return CURRENCY_WITH_NON_DECIMAL_POINTS.includes(
    currency?.toUpperCase()
  );
};
